$welcome-color: rgb(250, 250, 250);
$main-color: rgb(153, 194, 148);
$second-color: rgb(86, 134, 79);
$letter-color: rgb(255, 255, 255);
$background-color: rgb(255, 255, 255);
$h-color: rgb(255, 255, 255);
$text-color: rgb(26, 22, 18);
$menu-animate: rgb(36, 36, 36);
$main-gradient: linear-gradient(
	321deg,
	rgba(215, 215, 215, 1) 14%,
	rgba(82, 82, 82, 1) 99%
);

$footer-gradient: linear-gradient(
	321deg,
	rgba(215, 215, 215, 1) 14%,
	rgba(82, 82, 82, 1) 99%
);

$nav-gradient: linear-gradient(
	274deg,
	rgba(148, 145, 145, 1) 15%,
	rgba(82, 82, 82, 1) 100%
);
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a[href^="tel"] {
	color: inherit;
	text-decoration: none;
}

button {
	color: #000;
}

.wrapper {
	margin: 0 auto;
	max-width: 1600px;
	width: 100%;
}

.mini-wrapper {
	margin: 0 auto;
	max-width: 1200px;
	width: 100%;
}
html {
	font-size: 62.5%;
	scroll-behavior: smooth;
	font-family: "Cinzel", serif;
	overflow-x: hidden;
	// overflow-y: hidden;
	scroll-padding-top: 10rem;
}

body {
	position: relative;
	min-height: 100vh;
	background: $main-gradient;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
h2 {
	margin-bottom: 3rem;
	padding: 5rem 0 2rem 0;
	font-family: "Cinzel", serif;
	font-size: 3rem;
	text-align: center;
	color: $letter-color;
}

.welcome {
	&__wrapper {
		position: relative;
		margin: 0 auto;
		max-width: 1600px;
		width: 100%;
		height: 100vh;
	}
	&__img {
		display: none;
		position: sticky;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-image: url("/dist/img/field_small.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 100;
	}
	&__shadow {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.4);
	}
	&__arrow {
		position: absolute;
		bottom: 10%;
		left: 50%;
		font-size: 3rem;
		color: $welcome-color;
		z-index: 10;
		animation: welcomearrow 2s linear infinite forwards;
		transform-origin: left;
		cursor: pointer;
		&--circle {
			transform: translateX(-50%);
		}
	}
	&__company {
		position: absolute;
		font-family: "Cinzel", serif;
		top: 10%;
		left: 10%;
		font-size: 2rem;
		color: $welcome-color;
	}

	&__quote {
		position: absolute;
		top: 30%;
		right: 10%;
		width: 50%;
		font-size: 1.4rem;
		line-height: 1.6rem;
		color: $welcome-color;
	}
}

.navmobile {
	position: relative;
	overflow: hidden;
	height: 7rem;

	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: fixed;
		width: 100vw;
		height: 100%;
		background: $main-gradient;
		z-index: 100;
		transform: translateX(100%);
		transition: transform 1s;
		&--exit {
			position: absolute;
			padding: 1rem;
			top: 5rem;
			right: 5rem;
			font-size: 3rem;
			color: $letter-color;
			background: none;
			border: none;
			cursor: pointer;
		}

		&--active {
			transform: translateX(0%);
		}
	}

	&__link {
		width: 100%;
		padding: 3rem;
		font-size: 2.5rem;
		text-decoration: none;
		text-align: center;
		color: $letter-color;
		z-index: 120;
		transition: background-color 0.5s;
		&:hover {
			background-color: $menu-animate;
		}

		&--animation {
			animation: menuItemsAnim 1s both;
		}
	}

	&__company {
		position: absolute;
		top: 2rem;
		left: 1.5rem;
		font-size: 2rem;
		font-family: "Cinzel", serif;
		color: $letter-color;
	}

	&__btnbox {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
	}

	&__button {
		position: fixed;
		top: 1.5rem;
		right: 3rem;
		padding: 0.5rem;
		background-color: $menu-animate;
		border: 1px solid $letter-color;
		border-radius: 3px;
		font-size: 2rem;
		color: $letter-color;
		cursor: pointer;
		z-index: 50;
	}
}

.navdesktop {
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	height: 10rem;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	background: $nav-gradient;
	z-index: 20;
	opacity: 1;

	&__wrapper {
		position: relative;
		margin: 0 auto;
		max-width: 1600px;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;
	}

	&__company {
		width: 15%;
		margin-left: 1rem;
		font-family: "Cinzel", serif;
		color: $letter-color;
	}

	&__link {
		text-decoration: none;
		color: $letter-color;
		&--active {
			color: black;
			text-shadow: 1px 1px rgb(39, 37, 37);
		}
	}

	&__list {
		position: relative;
		width: 80%;
		text-align: right;
		margin-right: 0.5rem;

		button:first-child {
			display: none;
		}

		&--slice {
			position: relative;
			height: 10rem;
			margin-right: 0.5rem;
			padding: 1rem 0.8rem;
			font-family: "Cinzel", serif;
			font-size: 1.6rem;
			border: none;
			background: none;
			cursor: pointer;
			overflow: hidden;
			z-index: 0;

			a {
				padding: 5rem 0 5rem 0;
			}

			&::after,
			&::before {
				display: block;
				position: absolute;
				content: "";
				transition: all 0.5s;
				z-index: -1;
			}
			&::before,
			&::after {
				width: 100%;
				height: 100%;
				background-color: $menu-animate;
			}

			&::before {
				top: -50%;
				left: -100%;
			}

			&::after {
				bottom: -50%;
				right: -100%;
			}

			&:hover::before {
				left: 0;
			}
			&:hover::after {
				right: 0;
			}
		}
	}
}

header {
	position: relative;
	.header {
		&__img {
			position: relative;
			height: 35rem;
			background-image: url("/dist/img/fabric_small.jpg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			overflow: hidden;
		}
		&__shadow {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.2);
		}
		&__text {
			position: relative;
			height: 100%;
		}

		&__empty {
			display: none;
			height: 10rem;
		}
	}
	h1 {
		position: absolute;
		bottom: 10%;
		left: 10%;
		right: 10%;
		font-size: 2.2rem;
		font-family: "Cinzel", serif;
		font-weight: 200;
		color: $h-color;
		text-align: center;
	}
}

.aboutus {
	position: relative;

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	&__box {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 30rem;
		width: 60vw;
		background-color: $background-color;
		border-radius: 20px;
		margin-bottom: 2rem;
	}

	&__title {
		padding: 1rem;
		margin-bottom: 1rem;
		height: 5rem;
		color: $text-color;
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
	}
	&__line {
		height: 0.2rem;
		width: 75%;
		border-bottom: 1px solid $text-color;
	}

	&__text {
		width: 90%;
		padding: 1rem;
		font-size: 1.2rem;
		color: $text-color;
		text-align: center;
	}
}

.linen {
	position: relative;
	padding-top: 2rem;
	h2 {
		margin-bottom: 1rem;
		padding: 0;
	}
	h3 {
		margin-bottom: 2rem;
		padding: 0 0 1rem 0;
		font-family: "Cinzel", serif;
		font-size: 2rem;
		text-align: center;
		color: $letter-color;
	}
	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&__card {
		position: relative;
		margin: 2rem 3rem;
		width: 100%;
		max-width: 30rem;
		height: 40rem;
		perspective: 50rem;
		border-radius: 5rem;

		h3 {
			margin-bottom: 1rem;
			padding: 1rem;
			color: $text-color;
		}
	}

	&__questionmark {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 3rem 4rem;
		border: 2px solid black;
		border-radius: 50%;
		background-color: rgba(240, 248, 255, 0.568);
		font-size: 8rem;
		font-weight: bold;
		transform: translate(-50%, -50%);
		transition: transform 0.8s, opacity 0.8s, display 0.8s;
	}

	&__img {
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		border-radius: 5rem;
		transition: transform 0.8s, opacity 0.8s;

		&--one {
			background-image: url("/dist/img/one.jpg");
		}
		&--two {
			background-image: url("/dist/img/two.jpg");
		}
		&--three {
			background-image: url("/dist/img/three.jpg");
		}
		&--four {
			background-image: url("/dist/img/four.jpg");
		}
	}

	&__text {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		padding: 3rem;
		background-color: $background-color;
		border-radius: 5rem;
		font-size: 1.4rem;
		text-align: center;
		opacity: 0;
		transition: transform 0.8s, opacity 0.8s;
		transform: rotateX(-180deg);
	}

	&__answer {
		display: none;
		position: absolute;
		width: 40rem;
		top: 50%;
		left: 50%;
		font-size: 2.5rem;
		font-weight: bold;
		text-align: center;
		transition: transform 0.8s, opacity 0.8s;
		transform: translate(-50%, -50%) rotate(-50deg);
	}

	&__card:hover &__text {
		transform: rotateX(0);
		opacity: 1;
	}
	&__card:hover &__img {
		transform: rotateX(180deg);
		opacity: 0;
	}
	&__card:hover &__questionmark {
		transform: rotateX(180deg translate(-50%, -50%));
		transform-origin: left;
		opacity: 0;
	}

	&__card:hover &__answer {
		transform: rotateX(180deg translate(-50%, -50%));
		transform-origin: center;
		opacity: 0;
	}

	&__animation {
		display: inline;
		position: absolute;
		top: -5%;
		left: 10%;
		width: 25rem;
		opacity: 0.1;
		z-index: -5;
		animation: rotate-right 50s infinite linear;
	}
}

.offer {
	position: relative;
	color: $letter-color;
	h2 {
		padding: 2rem 0 0rem 0;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&__img {
		img {
			width: 80vw;
			margin-top: 1rem;
			padding: 2rem;
			border-radius: 3rem;
		}
	}

	&__list {
		text-align: center;
		h3 {
			margin: 0 1rem 0 1rem;
			padding-bottom: 1rem;
			font-size: 2rem;
		}
		li {
			margin: 0 1rem 0 1rem;
			padding: 0.5rem;
			font-size: 1.4rem;
			list-style: none;
		}
	}

	&__animation {
		display: inline;
		position: absolute;
		top: -10%;
		right: 10%;
		width: 30rem;
		opacity: 0.05;
		z-index: -5;
		animation: rotate-left 70s infinite linear;
	}
}

.contact {
	position: relative;
	h2 {
		padding: 2rem 0 1rem 0;
	}
	&__container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	&__text {
		position: relative;
		padding: 0 4rem 4rem 4rem;
		font-size: 1.7rem;
		color: $letter-color;
		&--free {
			color: rgb(248, 54, 64);
		}
	}

	&__phone {
		padding-bottom: 1rem;
		font-size: 3rem;
		font-weight: bold;
		text-align: center;
	}

	form {
		display: flex;
		flex-direction: column;
		text-align: center;
		font-family: "Cinzel", serif;

		label {
			padding: 0.5rem;
			font-size: 1.5rem;
			font-weight: bold;
			color: $letter-color;
		}
		input {
			width: 80vw;
			padding: 0.2rem;
			font-family: "Cinzel", serif;
			border: 1px solid black;
			border-radius: 0.5rem;
		}
		textarea {
			max-width: 80vw;
			font-family: "Cinzel", serif;
			font-size: 1.5rem;
			padding: 0.2rem;
			min-height: 20rem;
			border: 1px solid black;
			border-radius: 0.5rem;
		}

		.contact__confirm {
			width: 15rem;
			height: 7rem;
			margin-top: 2rem;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 2rem;
			padding: 2rem 4rem;
			font-family: "Cinzel", serif;
			font-weight: bold;
			background-color: #f0f0f0;
			color: black;
			border: 1px solid black;
			border-radius: 2rem;
			cursor: pointer;
			transition: background-color 0.5s, color 0.5s;
			&:hover {
				color: white;
				background-color: black;
			}
		}
	}

	&__animation {
		position: absolute;
		top: 30%;
		left: 50%;
		width: 40%;
		opacity: 0.1;
		transform: translate(-50%, -50%);
		z-index: -5;
	}
}

footer {
	height: 20rem;
	background: linear-gradient(
		321deg,
		rgb(180, 180, 180) 14%,
		rgb(48, 48, 48) 99%
	);
	margin-top: auto;

	.footer {
		&__container {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__date {
			position: absolute;
			right: 50%;
			bottom: 1rem;
			color: $letter-color;
			font-size: 1rem;
			text-align: center;
			transform: translateX(50%);
		}

		&__address {
			margin-left: auto;
			margin-right: 5rem;
			line-height: 2rem;
			margin-bottom: 2rem;
			p {
				color: white;
				font-size: 1.2rem;
			}

			p:nth-child(1) {
				font-size: 1.5rem;
				font-weight: bold;
			}

			hr {
				color: white;
			}
		}
	}
}
