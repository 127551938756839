@keyframes welcomearrow {
	0% {
		transform: scale(1);
	}

	20% {
		transform: scale(1.1);
	}

	40% {
		transform: scale(1.2);
	}

	60% {
		transform: scale(1.3);
	}

	80% {
		transform: scale(1.4);
	}

	100% {
		transform: scale(1.5);
	}
}

@keyframes menuItemsAnim {
	from {
		transform: translateX(200%);
	}

	to {
		transform: translateX(0%);
	}
}

@keyframes rotate-right {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes rotate-left {
	from {
		transform: rotate(360deg);
	}

	to {
		transform: rotate(0);
	}
}
