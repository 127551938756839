@media (-webkit-device-pixel-ratio: 3) {
	.contact {
		form {
			.contact__confirm {
				height: 5rem;
			}
		}
	}
}

@media (min-width: 320px) {
	.welcome {
		&__img {
			background-image: url("/dist/img/field_medium.jpg");
		}
	}
	header {
		.header {
			&__img {
				background-image: url("/dist/img/fabric_medium.jpg");
			}
		}
		h1 {
			font-size: 2.4rem;
		}
	}
}

@media (min-width: 576px) {
	h2 {
		font-size: 3.5rem;
	}
	.welcome {
		&__company {
			font-size: 3rem;
		}

		&__quote {
			line-height: 2rem;
		}
		&__arrow {
			font-size: 4rem;
		}
	}
	.navmobile {
		display: none;
	}
	.navdesktop {
		display: flex;
		&__company {
			font-size: 2.5rem;
		}
	}
	header {
		.header {
			&__empty {
				display: block;
			}
		}
		h1 {
			bottom: 15%;
			font-size: 3.5rem;
		}
	}

	.aboutus {
		&__title {
			width: 50vw;
			font-size: 2rem;
		}

		&__text {
			margin-top: 2rem;
			font-size: 1.5rem;
		}
	}
	.linen {
		&__animation {
			top: 2%;
			left: 10%;
		}
	}
	.offer {
		&__img {
			img {
				max-width: 50rem;
			}
		}
		&__list {
			h3 {
				font-size: 2.4rem;
				padding-bottom: 2rem;
			}
			li {
				text-align: start;
				font-size: 1.8rem;
				list-style: disc;
			}
		}

		&__animation {
			top: 5%;
			right: 10%;
		}
	}
	.contact {
		&__animation {
			width: 30%;
		}
	}
}

@media (min-width: 768px) {
	h2 {
		font-size: 4rem;
	}
	.welcome {
		&__company {
			font-size: 4rem;
		}

		&__quote {
			width: 40%;
			font-size: 1.8rem;
			line-height: 2.5rem;
		}
	}

	.navdesktop {
		&__company {
			margin-left: 2.5rem;
			font-size: 2.8rem;
		}
		&__list {
			margin-right: 3rem;
			&--slice {
				padding: 1rem 1.9rem;
				font-size: 1.8rem;
				font-weight: bold;
			}
		}
	}
	header {
		.header {
			&__img {
				height: 35rem;
			}
		}
		h1 {
			font-size: 3.5rem;
		}
	}
	.aboutus {
		&__container {
			flex-direction: row;
			flex-wrap: wrap;
		}

		&__box {
			width: 40rem;
			margin-left: 1.5rem;
			margin-right: 1.5rem;
			margin-bottom: 3rem;
		}
	}

	.linen {
		h3 {
			font-size: 2.4rem;
		}
	}
	.offer {
		&__img {
			img {
				max-width: 60rem;
			}
		}
		&__list {
			h3 {
				font-size: 2.6rem;
			}
			li {
				font-size: 2rem;
			}
		}

		&__animation {
			top: 12%;
		}
	}
	.contact {
		&__animation {
			width: 25%;
		}
	}
}

@media (min-width: 992px) {
	.welcome {
		&__img {
			background-image: url("/dist/img/field_big.jpg");
		}
		&__quote {
			width: 35%;
		}
		&__arrow {
			font-size: 7rem;
		}
	}
	.navdesktop {
		&__company {
			margin-left: 4rem;
			font-size: 3rem;
		}
		&__link {
			&--home {
				display: inline;
			}
		}
		&__list {
			button:first-child {
				display: inline;
			}
		}
	}

	header {
		.header {
			&__img {
				height: 40rem;
				background-image: url("/dist/img/fabric_medium.jpg");
			}
		}
		h1 {
			left: auto;
			right: 5%;
			font-size: 5rem;
		}
	}

	.linen {
		&__container {
			&--wrapper {
				margin: 0 auto;
				margin-bottom: 3rem;
				max-width: 75vw;
				width: 100%;
			}
		}
		&__card {
			max-width: 30rem;
			height: 40rem;
		}
		&__animation {
			width: 35rem;
		}
	}
	.offer {
		&__container {
			flex-direction: row-reverse;
		}
		&__img {
			img {
				margin-right: 3rem;
				width: 45rem;
			}
		}

		&__animation {
			top: 70%;
		}
	}

	.contact {
		&__container {
			flex-direction: row;
		}

		&__text {
			font-size: 2rem;
			padding-bottom: 10rem;
			text-align: center;
		}
		form {
			padding: 0 5rem 5rem 1rem;
			input {
				width: 45rem;
			}
		}

		&__animation {
			width: 60%;
		}
	}
	footer {
		height: 25rem;
		.footer {
			&__address {
				line-height: 2.5rem;
				p {
					font-size: 1.5rem;
				}

				p:nth-child(1) {
					font-size: 1.8rem;
				}
			}
		}
	}
}

@media (min-width: 1200px) {
	h2 {
		font-size: 4.5rem;
	}
	.linen {
		&__card {
			max-width: 36rem;
			height: 48rem;
		}
	}
	.offer {
		&__img {
			img {
				margin-right: 5rem;
				width: 60rem;
			}
		}
	}
	.contact {
		&__text {
			font-size: 2.5rem;
		}
	}
}

@media (min-width: 1500px) {
	.linen {
		&__container {
			&--wrapper {
				margin: 0 auto;
				max-width: 1600px;
				width: 100%;
			}
		}
		&__card {
			max-width: 30rem;
			height: 40rem;
		}
	}
}
@media (min-width: 1800px) {
	header {
		.header {
			&__img {
				background-image: url("/dist/img/fabric_big.jpg");
			}
		}
	}
}
